<template>
  <div class="pb-6">
    <v-card elevation="1" class="mb-6">
      <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
        <v-row class="px-6 pt-2">
          <v-col cols="5">
            <v-autocomplete
              v-model="formData.user.type"
              :items="listRoles"
              hide-no-data
              hide-selected
              item-text="label"
              item-value="label"
              placeholder="Busque pelo tipo de usuário"
              outlined
              dense
              :clearable="true"
              return-object
              @input="selectRole"
              required
              :rules="rules.selectRules"
              label="Tipo de Usuário"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="px-6 pb-4">
        <v-btn
          depressed
          color="fmq_gray"
          dark
          @click="send"
          :loading="loadingBtn"
          >Editar Funcionario</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card elevation="1">
      <v-form ref="formView" v-model="valid" lazy-validation class="pt-4">
        <v-row class="px-6 pt-2">
          <v-col cols="5">
            <v-text-field
              v-model="formData.name"
              outlined
              dense
              label="Nome"
              :readonly="true"
              disabled
            >
            </v-text-field>
            <v-text-field
              v-model="formData.email"
              outlined
              dense
              label="E-mail"
              :readonly="true"
              disabled
            >
            </v-text-field>
            <v-text-field
              v-model="formData.division.code"
              outlined
              dense
              label="Divisão"
              disabled
              :readonly="true"
            >
            </v-text-field>
            <v-text-field
              v-model="formData.saleForce.code"
              outlined
              dense
              label="Divisão"
              disabled
              :readonly="true"
            >
            </v-text-field>
            <v-text-field
              v-model="formData.sector.code"
              outlined
              dense
              label="Setor"
              disabled
              :readonly="true"
            >
            </v-text-field>
            <v-text-field
              v-model="formData.district.code"
              outlined
              dense
              label="Distrital"
              disabled
              :readonly="true"
            >
            </v-text-field>
            <v-text-field
              v-model="formData.regional.code"
              outlined
              dense
              label="Regional"
              disabled
              :readonly="true"
            >
            </v-text-field>
            <v-text-field
              v-model="formData.manager.name"
              outlined
              dense
              label="Nome do Superior"
              disabled
              :readonly="true"
            >
            </v-text-field>
            <v-text-field
              v-model="formData.manager.email"
              outlined
              dense
              label="Email do Superior"
              disabled
              :readonly="true"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "FuncionariosFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    funcionario: {
      type: Object,
      default: () => {},
    },
    listRoles: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formData: {
      name: null,
      email: null,
      user: {
        type: null,
      },
      division: {
        code: null,
      },
      saleForce: {
        code: null,
      },
      sector: {
        code: null,
      },
      district: {
        code: null,
      },
      regional: {
        code: null,
      },
      manager: {
        name: null,
        email: null,
      },
    },
    roleId: null,
    rules: rules,
    valid: true,
  }),
  created() {
    this.formData.name = this.funcionario.name ? this.funcionario.name : null;
    this.formData.email = this.funcionario.email
      ? this.funcionario.email
      : null;
    this.formData.user.type = this.funcionario.user
      ? this.funcionario.user.type
      : null;
    this.formData.division.code = this.funcionario.division
      ? this.funcionario.division.code
      : null;
    this.formData.saleForce.code = this.funcionario.saleForce
      ? this.funcionario.saleForce.code
      : null;
    this.formData.sector.code = this.funcionario.sector
      ? this.funcionario.sector.code
      : null;
    this.formData.district.code = this.funcionario.district
      ? this.funcionario.district.code
      : null;
    this.formData.regional.code = this.funcionario.regional
      ? this.funcionario.regional.code
      : null;
    this.formData.manager.name = this.funcionario.manager
      ? this.funcionario.manager.name
      : null;
    this.formData.manager.email = this.funcionario.manager
      ? this.funcionario.manager.email
      : null;
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.roleId);
    },
    selectRole(event) {
      if (event) {
        this.roleId = event.id;
      }
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
