<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/funcionarios"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
          <div class="d-flex align-center">
            <span>Status: </span>
            <v-chip
              :color="
                funcionario.status.toLowerCase() === 'ativo' ? 'green' : 'red'
              "
              small
              class="ml-2"
              text-color="white"
              >{{ funcionario.status.toUpperCase() }}</v-chip
            >
          </div>
        </div>
        <FuncionariosFormulario
          :funcionario="funcionario"
          :listRoles="listRoles"
          :loadingBtn="loadingBtn"
          @send="putEditEmployee"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Funcionário editado com sucesso"
      @close="goToFuncionariosList"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import FuncionariosFormulario from "@/components/funcionarios/FuncionariosFormulario";
import { showEmployee, editEmployee } from "@/services/employee";
import { listRolePermission } from "@/services/permission";
export default {
  name: "FuncionarioEditar",
  components: { FuncionariosFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Funcionários",
        disabled: false,
        to: "/funcionarios",
      },
      {
        text: "Editar Funcionário",
        disabled: true,
        to: "",
      },
    ],
    funcionario: [],
    loading: false,
    listRoles: [],
    success: false,
    error: false,
    messageError: null,
    loadingBtn: false,
  }),
  created() {
    this.getShowEmployee();
  },
  methods: {
    async getShowEmployee() {
      try {
        this.loading = true;
        const resp = await showEmployee(this.$route.params.id);
        this.funcionario = resp.data;
        await this.getListRolePermission();
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async putEditEmployee(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editEmployee(this.$route.params.id, { roleId: event }).then(
          () => {
            this.success = true;
          }
        );
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async getListRolePermission() {
      const resp = await listRolePermission();
      this.listRoles = resp.data;
    },
    goToFuncionariosList() {
      this.sucess = false;
      this.$router.push({ name: "Funcionarios" });
    },
  },
};
</script>

<style></style>
